import * as React from "react";
import * as XLSX from "xlsx";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import { useUser } from "../../componets/UserContext";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import Tooltip from "@mui/material/Tooltip";
import "jspdf-autotable";
import Icon from "@mdi/react";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import { mdiFileExcel } from "@mdi/js";
import { apiUrl } from "../../componets/apiConfig";
import useFullPageLoader from "../../mui/useFullPageLoader";
import DataTable from "react-data-table-component";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

const ViewUser = () => {
  const { userData } = useUser();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [data, setStaffData] = useState([]);
  const [dataa, setStaffDataa] = useState([]);

  useEffect(() => {

    loadStaffData();

  }, [userData]);

  const loadStaffData = async () => {
    showLoader();
    try {
      const result = await axios.get(
        `${apiUrl}/getUsers`
      );
      setStaffData(result.data || []);
      setStaffDataa(result.data || []);
      hideLoader();
    } catch (error) {
      hideLoader();
      console.error("Error fetching data: ", error);
    }
  };




  const deleteUser = (UserId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        handleDelete(UserId);
      }
    });
  };

  async function handleDelete(UserId) {
    if (UserId === 1) {
      Swal.fire("Forbidden!", "Deletion of this User is not allowed.", "error");
      return;
    }

    try {
      await axios.delete(`${apiUrl}/deleteUser/${UserId}`);

      Swal.fire("Deleted!", "User has been deleted.", "success");
      loadStaffData();
    } catch (error) {
      console.error(error);
      Swal.fire(
        "Error!",
        "An error occurred while deleting the user.",
        "error"
      );
    }
  }


  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const DownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("User", 20, 10);

    const columns = [
      "Name",
      "Mobile",
      "Email",
      "DOB",
      "Role",
      "Address",
    ];

    const rows = data.map((row) => [
      `${row.First_Name} ${row.Last_Name}`,
      row.Mobile,
      row.Email ? row.Email : "--",
      row.DOB ? formatDate(row.DOB) : "--",
      row.Role,
      row.Address ? row.Address : "--",
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("User.pdf");
  };

  const DownloadExcel = () => {
    const columns = [
      "Name",
      "Mobile",
      "Email",
      "DOB",
      "Role",
      "Address",
    ];
    const rows = [columns];
    data.forEach((row) => {
      rows.push([
        `${row.First_Name} ${row.Last_Name}`,
        row.Mobile,
        row.Email ? row.Email : "--",
        row.DOB ? formatDate(row.DOB) : "--",
        row.Role,
        row.Address ? row.Address : "--",
      ]);
    });

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "User");

    // Buffer
    //let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    // Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    // Download
    XLSX.writeFile(workBook, "User.xlsx");
  };

  function handleFilter(event) {
    const filterValue = event.target.value.toLowerCase();
    const filteredData = dataa.filter((row) => {
      return (
        (`${row.First_Name} ${row.Last_Name}` &&
          `${row.First_Name} ${row.Last_Name}`
            .toLowerCase()
            .includes(filterValue)) ||
        (row.Mobile && row.Mobile.toLowerCase().includes(filterValue)) ||
        (formatDate(row.DOB) && formatDate(row.DOB).toLowerCase().includes(filterValue)) ||
        (row.Email && row.Email.toLowerCase().includes(filterValue)) ||
        (row.Role && row.Role.toLowerCase().includes(filterValue)) ||
        (row.Address && row.Address.toLowerCase().includes(filterValue))
      );
    });
    setStaffData(filteredData);
  }

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
            <h5 className="page-title">View User</h5>
          </div>
          <div className="card">
            <div className="card-body">
              <Stack
                direction="row"
                spacing={2}
                className="mx-2 my-2 mb-2"
                sx={{ zIndex: 0, position: "relative" }}
              >
                <TextField
                  onChange={handleFilter}
                  size="small"
                  label="Search..."
                />
                <div>
                  <Tooltip title="Export to PDF">
                    <i
                      style={{
                        cursor: "pointer",
                        padding: "5px",
                      }}
                      onClick={() => {
                        DownloadPDF();
                      }}
                    >
                      <PictureAsPdfIcon />
                    </i>
                  </Tooltip>

                  <Tooltip title="Export to Excel">
                    <i
                      style={{
                        padding: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        DownloadExcel();
                      }}
                    >
                      {" "}
                      <Icon path={mdiFileExcel} size={1} />
                    </i>
                  </Tooltip>
                </div>
              </Stack>

              <TableContainer
                className=""
                sx={{
                  maxHeight: "100%",
                  minHeight: "170px",
                  zIndex: 0,
                  position: "relative",
                }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>

                      <TableCell className="text-left" style={{ minWidth: "100px" }}>
                        Name
                      </TableCell>
                      <TableCell className="text-left" style={{ minWidth: "100px" }}>
                        Mobile No
                      </TableCell>
                      <TableCell className="text-left" style={{ minWidth: "100px" }}>
                        Email
                      </TableCell>
                      <TableCell className="text-left" style={{ minWidth: "100px" }}>
                        DOB
                      </TableCell>
                      <TableCell className="text-left" style={{ minWidth: "69px" }}>
                        Role
                      </TableCell>
                      <TableCell className="text-left" style={{ minWidth: "130px" }}>
                        Address
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data && data.length > 0 ? (
                      data
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          return (
                            <TableRow
                              key={index}
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              style={{ height: "30px" }}
                            >
                              <TableCell className="text-left" style={{ padding: "5px" }}>
                                {`${row.First_Name} ${row.Last_Name}`}
                              </TableCell>
                              <TableCell
                                className="text-left"
                                style={{ padding: "5px" }}
                              >{row.Mobile}</TableCell>
                              <TableCell className="text-left" style={{ padding: "5px" }}>
                                <LightTooltip title={row.Email}>
                                  <span
                                    style={{
                                      // maxWidth: "90px",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      padding: "5px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {row.Email ? row.Email : "--"}
                                  </span>
                                </LightTooltip>
                              </TableCell>
                              <TableCell
                                className="text-left"
                                style={{ padding: "5px" }}
                              >{row.DOB ? formatDate(row.DOB) : "--"}</TableCell>
                              <TableCell className="text-left" style={{ padding: "5px" }}>
                                {row.Role}
                              </TableCell>
                              <TableCell
                                className="text-left"
                                style={{
                                  maxWidth: "130px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  padding: "5px",
                                }}
                              >
                                <LightTooltip title={row.Address}>
                                  <span
                                    style={{
                                      maxWidth: "70px",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      padding: "5px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {row.Address ? row.Address : "--"}
                                  </span>
                                </LightTooltip>
                              </TableCell>

                              <TableCell
                                className="text-cneter"
                                style={{ padding: "5px" }}
                              >
                                <div className="dropdown">
                                  <a
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i>
                                      <MoreVertIcon
                                        style={{
                                          color: "grey",
                                          fontSize: "18px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </i>
                                  </a>
                                  <div
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    <Link
                                      className="dropdown-item"
                                      to={`/updateUser/${row.UserId}`}
                                    >
                                      <EditIcon
                                        className="text-info"
                                        style={{ fontSize: "18px" }}
                                      />{" "}
                                      Edit
                                    </Link>
                                    <Link
                                      className="dropdown-item"
                                      onClick={() => deleteUser(row.UserId)}
                                    >
                                      <DeleteIcon
                                        className="text-danger"
                                        style={{ fontSize: "18px" }}
                                      />
                                      Delete
                                    </Link>
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6} className="text-center text-danger">
                          No Records Found!
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className="mt-3"
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </div>

        {loader}
      </div>
    </div>
  );
};
export default ViewUser;
