import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Tooltip from "@mui/material/Tooltip";
import { useUser } from "../../componets/UserContext";
import { apiUrl } from "../../componets/apiConfig";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import useButtonLoader from "../../mui/useButtonLoader";
import useFullPageLoader from "../../mui/useFullPageLoader";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const UpdateVendor = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const navigate = useNavigate();
  const { Sr_No } = useParams();
  const [errors, setErrors] = useState({});
  const { userData } = useUser();
  const [buttonElement, setLoading] = useButtonLoader(
    "Update Vendor",
    "Updating..."
  );



  const [vendor, setVendor] = useState({
    Org_Name: "",
    Org_Mobile: "",
    SupplierInfo: [{ Supplier_Name: "", Supplier_Mobile: "" }],
    Address: "",
    Added_By_Id: "",
    Added_By: "",
  });

  const [supplierErrors, setSupplierErrors] = useState([{}]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        showLoader();
        const vendorResult = await axios.get(`${apiUrl}/vendors/${Sr_No}`);
        const supplierResult = await axios.get(`${apiUrl}/suppliers/${Sr_No}`);

        setVendor((prevVendor) => ({
          ...prevVendor,
          Org_Name: vendorResult.data.Org_Name,
          Org_Mobile: vendorResult.data.Org_Mobile,
          Address: vendorResult.data.Address,
          Added_By_Id: vendorResult.data.Added_By_Id,
          Added_By: vendorResult.data.Added_By,
          SupplierInfo: Array.isArray(supplierResult.data)
            ? supplierResult.data
            : [supplierResult.data],
        }));
        hideLoader();
      } catch (error) {
        console.error(error);
        hideLoader();
        // Handle errors here
      }
    };

    fetchData();
  }, [Sr_No]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const updatedVendor = {
      ...vendor,
      Added_By_Id: userData.userId,
      Added_By: `${userData.firstName} ${userData.lastName}`,
    };

    const validationErrors = {};

    // Validate the Supplier_Mobile fields
    const updatedSupplierErrors = vendor.SupplierInfo.map((supplier) => {
      const errors = {};

      if (!/^([6-9][0-9]{9,11})$/.test(supplier.Supplier_Mobile)) {
        errors.Supplier_Mobile = "Invalid Mobile Number";
      }

      return errors;
    });

    setSupplierErrors(updatedSupplierErrors);

    if (
      updatedSupplierErrors.some((errors) => Object.keys(errors).length > 0)
    ) {
      return;
    }

    if (!/^([6-9][0-9]{9,11})$/.test(vendor.Org_Mobile)) {
      validationErrors.Org_Mobile = "Invalid Mobile Number";
    }

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      showLoader();
      axios
        .put(`${apiUrl}/updateVendor/${Sr_No}`, updatedVendor)
        .then((res) => {
          Swal.fire("Success!", "Vendor Record has been updated.", "success");
          navigate("/viewVender");
          setLoading(false);
          hideLoader();
        })
        .catch((err) => console.log(err));
      hideLoader();
    } else {
      setErrors(validationErrors);
    }
  };

  const handleAddSupplier = () => {

    const updatedSupplierInfo = [
      ...vendor.SupplierInfo,
      { Supplier_Name: "", Supplier_Mobile: "" },
    ];
    setVendor({ ...vendor, SupplierInfo: updatedSupplierInfo });
    setSupplierErrors([...supplierErrors, {}]);
  };

  const handleSupplierInfoChange = (index, field, value) => {
    // Clone the current SupplierInfo array
    const updatedSupplierInfo = [...vendor.SupplierInfo];

    // Update the specified field of the supplier at the given index
    updatedSupplierInfo[index][field] = value;

    // Update the state with the new array
    setVendor({ ...vendor, SupplierInfo: updatedSupplierInfo });
  };

  const handleRemoveSupplier = (index) => {
    const updatedSupplierInfo = vendor.SupplierInfo.filter(
      (_, idx) => idx !== index
    );
    const updatedSupplierErrors = supplierErrors.filter(
      (_, idx) => idx !== index
    );

    // Don't remove if there's only one pair of fields
    if (vendor.SupplierInfo.length <= 1) {
      return;
    }

    setVendor({ ...vendor, SupplierInfo: updatedSupplierInfo });
    setSupplierErrors(updatedSupplierErrors);
  };

  return (
    <div className="row">
      <div className="col-12 ">
        <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
          <h4 className="page-title">Update Vendor</h4>
        </div>
        <div className="card">
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-5">
                  <div className="mb-3">
                    <label htmlFor="Org_Name" className="form-label">
                      Organization Name*
                    </label>
                    <input
                      type="text"
                      id="Org_Name"
                      name="Org_Name"
                      required
                      value={vendor.Org_Name}
                      onChange={(e) =>
                        setVendor({ ...vendor, Org_Name: e.target.value })
                      }
                      className="form-control"
                      placeholder="Enter Organization Name"
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="mb-3">
                    <label htmlFor="Org_Mobile" className="form-label">
                      Organization Mobile*
                    </label>
                    <input
                      type="text"
                      name="Org_Mobile"
                      className={`form-control ${
                        errors.Org_Mobile ? "is-invalid" : ""
                      }`}
                      id="Org_Mobile"
                      required
                      maxLength="10"
                      value={vendor.Org_Mobile}
                      onChange={(e) => {
                        const input = e.target.value;
                        const numericInput = input.replace(/[^0-9]/g, "");
                        setVendor({ ...vendor, Org_Mobile: numericInput });
                      }}
                      placeholder="Enter Organization Mobile Number"
                    />
                    {errors.Org_Mobile && (
                      <div className="invalid-feedback">
                        {errors.Org_Mobile}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                {vendor.SupplierInfo.map((supplier, index) => (
                  <div className="row" key={index}>
                    <div className="col-md-5">
                      <div className="mb-3">
                        <label
                          htmlFor={`Supplier_Name_${index}`}
                          className="form-label"
                        >
                          Supplier Name*
                        </label>
                        <input
                          type="text"
                          id={`Supplier_Name_${index}`}
                          name={`Supplier_Name_${index}`}
                          required
                          value={supplier.Supplier_Name}
                          onChange={(e) =>
                            handleSupplierInfoChange(
                              index,
                              "Supplier_Name",
                              e.target.value
                            )
                          }
                          className="form-control"
                          placeholder="Enter Supplier Name"
                        />
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="mb-3">
                        <label
                          htmlFor={`Supplier_Mobile_${index}`}
                          className="form-label"
                        >
                          Supplier Mobile*
                        </label>
                        <input
                          type="text"
                          id={`Supplier_Mobile_${index}`}
                          name={`Supplier_Mobile_${index}`}
                          className={`form-control ${
                            supplierErrors[index]?.Supplier_Mobile
                              ? "is-invalid"
                              : ""
                          }`}
                          required
                          value={supplier.Supplier_Mobile}
                          onChange={(e) =>
                            handleSupplierInfoChange(
                              index,
                              "Supplier_Mobile",
                              e.target.value
                            )
                          }
                          maxLength="10"
                          placeholder="Enter Supplier Mobile Number"
                        />
                        {supplierErrors[index]?.Supplier_Mobile && (
                          <div className="invalid-feedback">
                            {supplierErrors[index].Supplier_Mobile}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-md-2">
                      {vendor.SupplierInfo.length > 1 && (
                        <Tooltip title="Remove Supplier">
                          <RemoveCircleOutlineIcon
                            onClick={() => handleRemoveSupplier(index)}
                            style={{
                              cursor: "pointer",
                              position: "relative",
                              top: "36px",
                              left: "30px",
                            }}
                          />
                        </Tooltip>
                      )}
                    </div>
                  </div>
                ))}

                <div
                  className="row"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <div className="col-md-2">
                    <Tooltip title="Add More Supplier">
                      <AddCircleOutlineIcon
                        style={{
                          cursor: "pointer",
                          position: "relative",
                          top: "-50px",
                        }}
                        onClick={handleAddSupplier}
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="Address" className="form-label">
                      Address*
                    </label>
                    <textarea
                      className="form-control"
                      id="Address"
                      name="Address"
                      placeholder="Address"
                      required
                      value={vendor.Address}
                      onChange={(e) =>
                        setVendor({ ...vendor, Address: e.target.value })
                      }
                      multiline="true"
                      rows={4}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center">
                  <button
                    type="submit"
                    className="btn btn-primary "
                    ref={buttonElement}
                  >
                    Update Vendor
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {loader}
      </div>
    </div>
  );
};

export default UpdateVendor;
